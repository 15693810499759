import React, { useState, useEffect } from 'react';
import '../../../assets/css/event.css';
import '../../../App.css';
import Modal from "react-bootstrap/Modal";
import Services from '../../../services/global.service';
import { useParams } from 'react-router';
import closeIcon from '../../../assets/img/svg/close-outline.svg';
import Footer from '../../../components/footer';
import Layout from '../../layout';


function DetailsAnimateur() {

    const [animateur, setAnimateur]=useState(null);
    const params = useParams();
    const getAnimateurDetails=()=>{
        Services.getOne(params.id,'user/get')
        .then((res)=>{
            console.log(res);
            setAnimateur(res?.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    useEffect(()=>{
        getAnimateurDetails();
      },[]);

  



  return (

    <Layout>
    <div className="col pt-3 bg-fond">
     <div className="row"> 
     <div className="m-vh-100  p-40">
     <div className="container">
     
     <div className="d-flex justify-content-between"><p className="d-title">Détails animateur  <span className="border-title"></span> 
     </p> </div>
     
      <div className="space-72"></div>
    
      
      </div>  
      <div className="container event">
        <p>Prenom : {animateur?.firstName}</p>
        <p>Nom : {animateur?.lastName}</p>
        <p>Email : {animateur?.email}</p>
        <p>Stade : {animateur?.stade?.name}, {animateur?.stade?.address}</p>
      </div>
        </div> 
        <Footer/>
        </div>
    
        </div>
       </Layout>
  );
}

export default DetailsAnimateur;
