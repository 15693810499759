import React, { useState, useEffect } from "react";
import "../../../src/assets/css/event.css";
import trash from "../../../src/assets/img/svg/trash.svg";
import edit from "../../../src/assets/img/svg/edit.svg";
import Footer from "../../components/footer";
import "../../App.css";
import Datatables from "../../components/datatables";
import Layout from "../layout";
import ModalUp from "../../components/modal";
import { useParams } from "react-router";
import Services from "../../services/global.service";
import chevron from "../../assets/img/svg/chevron-right.svg";
import iconBack from "../../assets/img/svg/arrow-ios-back-outline.svg";
import moment from "moment";
import Card from "../../components/card";
import ModalAnimation from "../../components/modalAnimation";
import DeleteModal from "../../components/deleteModal";

function AllMessages() {
  const [event, setEvent] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [idTodelete, setIdTodelete] = useState();
  const params = useParams();

  function handleDelete(id) {}
  function HandleScheduleAnimation(){
   // setIsOpen(true);
  }
  function getMessage() {
    console.log("id event", params.id);
    Services.getOne(params.id, "message/getAllByEvent")
      .then((response) => {
        console.log('messages', response);
        setMessages(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getEvent() {
    console.log("id event", params.id);
    Services.getOne(params.id, "event/getOne")
      .then((response) => {
        console.log(response);
        setEvent(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const deleteMessage=(id)=>{
  console.log('deleteMessage all', id)
  setIsOpenDelete(true)
  setIdTodelete(id)
  
  }
 const DeleteEvent=()=>{ 
  console.log('delete event all')
  Services.deleteByid(idTodelete,'message/delete')
  .then((res)=>{
    console.log('res', res)
  })
  .catch((err)=>{
    console.log(err)
  })
 
   
  }
  useEffect(() => {
    getEvent();
    getMessage();
  }, [isOpenDelete]);

  return (
    <>
       {isOpenDelete  ? <DeleteModal isOpenDelete={isOpenDelete} setIsOpenDelete={setIsOpenDelete} hideModal={()=>{setIsOpenDelete(false)}} 
    DeleteEvent={DeleteEvent} 
    title="Souhaitez-vous supprimer ce message ? "/>:""}
      <Layout>
        <div className="col pt-3 bg-fond">
          <div className="row">
            <div className="m-vh-100  ">
              <div className="container p-40">
                <div className="bread-crumb">
                  <span>Évènements</span>
                  <img src={chevron} alt="chevron"></img>
                  {event.title}{" "}
                  <img src={chevron} alt="chevron"></img>
                  <span className="bread-item">Les messages envoyés</span>
                </div>
                <div className="retour pt-30 mb-20">
                  <img src={iconBack} alt="iconBack"></img>
                  <button className="link" onClick={ ()=>{window.history.back();}}>Retour</button>
                </div>
                <div className="row">
                 <div className="col-md-6">
                  <div className="w-fitcontent">
                  <h1 className="d-title">{event.title}{" "}  <span className="border-title"></span></h1>{" "} </div> 
                      </div>
                 <div className="col-md-6">
                 
                  <a
                    className="btn-primary-up right"
                   href={`/dashboard/events/message/${params.id}`}
                  >
                    Envoyer un message aux supporters
                  </a></div>
                </div>
          
                <div className="pt-20">
                  <span className="pr-20 typo">
                    {moment(event?.date)?.format("MM/DD/YYYY")}
                  </span>
                  <span>
                  {event?.startTime?.replace(':','h')}-{event?.endTime?.replace(':','h')}
                  </span>
                </div>
                <div className="row pt-30">
                {messages?.map((message) => (
                  <div className="col-md-3 my-3">
                    <Card data={message}  deleteMessage={deleteMessage} />
                  </div>
                ))}
                </div>
                <div className="space-72"></div>
              
              </div>
              <div className="container">
                {/* {event && <Datatables
          data={event}
          columns={columns}/>}  */}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AllMessages;
