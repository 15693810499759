import React, { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "../../components/sidebar";
import "../../../src/assets/css/event.css";
import Footer from "../../components/footer";
import Layout from "../layout";
import trash from '../../assets/img/svg/trash.svg';
import edit from '../../assets/img/svg/edit.svg'
import { Card, CardGroup } from "react-bootstrap";

function Models() {
  const [events, setEvents] = useState([]);

  useEffect(() => {}, []);

  return (
    <Layout>
      <div className="col pt-3 bg-fond">
        <div className="row">
          <div className="m-vh-100  p-40">
            <div className="container">
              <div className="row">
                <div className="col">
                  <p className="d-title">Modèles des animations</p>{" "}
                  <span className="border-title"></span>
                </div>
                <div className="col">
                  <button className="btn-primary-up right">
                    Créer un nouveau modèle
                  </button>
                </div>
              </div>
              <div className="space-72"></div>
              <h2>Les up</h2>
              <div className="row">
                <div className="col-md-4">
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6"></div>  
                        </div>
                        Titre : Lorem ipsum 
                      <img src={trash} alt="icon trash"/>
                       <img src={edit} alt="icon trash"/> </Card.Title>
                      <Card.Text>
                        <p>Niveau : 1-2-3</p>
                        <p>Niveau : 1-2-3</p>
                        <p>Niveau : 1-2-3</p>
                        <p>Niveau : 1-2-3</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4">
                  <Card>
                    <Card.Body>
                      <Card.Title>Titre : Lorem ipsum</Card.Title>
                      <Card.Text>
                        <p>Niveau : 1-2-3</p>
                        <p>Niveau : 1-2-3</p>
                        <p>Niveau : 1-2-3</p>
                        <p>Niveau : 1-2-3</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4">
                  <Card>
                    <Card.Body>
                      <Card.Title>Titre : Lorem ipsum</Card.Title>
                      <Card.Text>
                        <p>Niveau : 1-2-3</p>
                        <p>Niveau : 1-2-3</p>
                        <p>Niveau : 1-2-3</p>
                        <p>Niveau : 1-2-3</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="space-72"></div>
              <h2>Les chants de stade </h2>
              <div className="row">
                <div className="col-md-4">
                  <Card>
                    <Card.Body>
                      <Card.Title>Titre : Lorem ipsum</Card.Title>
                      <Card.Text>
                        <p>Zone : 1-2-3</p>
                        <p>Accès : 1-2-3</p>
                       
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>{" "}
          </div>{" "}    
          <Footer />
        </div>

    
      </div>
    </Layout>
  );
}

export default Models;
