

import { Outlet ,Navigate} from "react-router";
import useadminAuth from "./adminRole";

export default function PrivateToAdmin() {
  const auth = useadminAuth();

  return auth ? <Outlet /> : <Navigate to="/Login" />;
}
