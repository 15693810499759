import '../assets/css/ui.css';
import deleteIcon from '../assets/img/svg/trash.svg';
import moment from 'moment';
const  Card=({data, deleteMessage})=>{
  const date = new Date(data?.createdAt);
  const formatDate = (inputDate) => {
    // Assuming inputDate is in a format recognized by moment, if not, parse it using moment
    const date = moment(inputDate);
  
    const formattedDate = date.format('DD/MM/YYYY - HH[h]mm');
  
    return formattedDate;
  };
  const formattedDate = formatDate(date);
  const hours = date.getHours();
    const minutes = date.getMinutes();
    return(
      
         <div className="card" >
    <div className="card-body">
      <label className="card-title heureMsg"> {formattedDate}</label>
    <button className="btn btn-link right  p-0" onClick={()=>{deleteMessage(data?._id)}}><img src={deleteIcon} className='' alt="delete icon"></img></button> 
      <p className="card-text">"{data?.message}"</p>
     
    </div>
  </div>);
}
export default Card;