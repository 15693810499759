import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersRectangle,faSignOut } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/sidebar.css';
import logo from '../assets/img/logo.png';
import params from '../assets/img/svg/param.svg';
import paramsActif from '../assets/img/svg/settings-actif.svg';
import event from '../assets/img/svg/calendarActive.svg';
import eventNotActif from '../assets/img/svg/calendar-notactif.svg';
import anim from '../assets/img/svg/anim.svg';
import animActif from '../assets/img/svg/model-actif.svg';
import { useNavigate } from 'react-router-dom';
function SideBar() {
  const navigate = useNavigate();
  const [closeSideBar, setCloseSideBar] = useState(false);
  const logOut=()=>{
    localStorage.removeItem('token');
    navigate('/login')
  }
  return (
    <div className={`col-auto col-md-2 col-xl-2 px-0 shadow-sidebar ${closeSideBar ? 'close-sidebar' : ''}`}>
      <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
        <button className="btn btn-link" onClick={() => { setCloseSideBar(!closeSideBar) }}>
          <img src={logo} alt="logo" className="logo" />
        </button>

        {!closeSideBar && <h3 className="fs-5 d-none d-sm-inline dark-txt">Parc des Princes </h3>}
        {localStorage.getItem('role')==="ROLE_ANIMATEUR" ? 
        <Nav className="flex-column">
          <NavLink to="/dashboard/events" className={({ isActive }) => (isActive ? "nav-item active" : "nav-item ")}>
            {({ isActive }) => (
              <>
                <img
                  src={isActive ? event : eventNotActif}
                  alt="event"
                  className="nav-img ml-15"
                />
                {!closeSideBar && <span className=" d-none d-sm-inline">Évènements</span>}
              </>
            )}
          </NavLink>
          {/* <NavLink to="/dashboard/Models" className={({ isActive }) => (isActive ? "nav-item active" : "nav-item ")}>
            {({ isActive }) => (
              <>
                <img
                  src={isActive ? animActif : anim}
                  alt="event"
                  className="nav-img"
                />
                {!closeSideBar && <span className="ms-1 d-none d-sm-inline ">Modèles animation</span>}
              </>
            )}
          </NavLink> */}
          <NavLink to="/dashboard/params" className={({ isActive }) => (isActive ? "nav-item active" : "nav-item ")}>
          {({ isActive }) => (
              <> <img    src={isActive ? paramsActif : params} alt="event" className="nav-img ml-15" />   
              {!closeSideBar && <span className=" d-none d-sm-inline ">Paramètres</span>}
              </>
            )}
          </NavLink>
        </Nav>
        :
        localStorage.getItem('role')==="ROLE_ADMIN" ?
        <Nav className="flex-column">
        {/* <NavLink to="/admin/users" className={({ isActive }) => (isActive ? "nav-item active" : "nav-item ")}>
            {({ isActive }) => (
              <>
               <FontAwesomeIcon icon={faUsersRectangle}  />
                {!closeSideBar && <span className="ms-1 d-none d-sm-inline">Gestion utilisateurs</span>}
              </>
            )}
          </NavLink> */}
          <NavLink to="/admin/animateurs" className={({ isActive }) => (isActive ? "nav-item active" : "nav-item ")}>
            {({ isActive }) => (
              <>
                 <FontAwesomeIcon icon={faUsersRectangle} />
                {!closeSideBar && <span className=" d-none d-sm-inline pl-15">Animateurs</span>}
              </>
            )}
          </NavLink>
          </Nav>
          :""}
          
   
      </div>
      <button onClick={()=>{logOut()}} className="link logout pl-30"> <FontAwesomeIcon icon={faSignOut} />  {!closeSideBar && <span className=" d-none d-sm-inline pl-13">Me déconnecter</span>}</button>
    </div>
  );
}

export default SideBar;
