import React, { useState, useEffect } from "react";
import "../../../src/assets/css/event.css";
import trash from "../../../src/assets/img/svg/trash.svg";
import edit from "../../../src/assets/img/svg/edit.svg";
import Footer from "../../components/footer";
import "../../App.css";
import Datatables from "../../components/datatables";
import Layout from "../layout";
import ModalUp from "../../components/modal";
import { useParams } from "react-router";
import Services from "../../services/global.service";
import chevron from "../../assets/img/svg/chevron-right.svg";
import iconBack from "../../assets/img/svg/arrow-ios-back-outline.svg";
import moment from "moment";
import Card from "../../components/card";
import ModalAnimation from "../../components/modalAnimation";
import DeleteModal from "../../components/deleteModal";
function DetailEvent() {
  const [event, setEvent] = useState([]);
  const [animations, setAnimations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [type, setType] = useState();
  const [isOpenDeleteAnimation, setIsOpenDeleteAnimation] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [idToDeleted, setIdToDelete] = useState('');
  const [idToDeletedAnim, setIdToDeleteAnim] = useState('');

  const params = useParams();
  function handleDelete(id) {
    setIdToDelete(id);
    setIsOpenDelete(true);
  }
  function HandleScheduleAnimation(){
    setIsOpen(true);
  }
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsChant, setSelectedRowsChant] = useState([]);
  const [idTodelete, setIdTodelete] = useState();

const handleRowSelected2 = (row,type) => {
  const selectedIndex = selectedRows.indexOf(row);
  const selectedIndexChant = selectedRowsChant.indexOf(row);
  let newSelectedRows = [];

  if (selectedIndex === -1) {
    newSelectedRows = newSelectedRows.concat(selectedRows, row);
  } else if (selectedIndex === 0) {
    newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
  } else if (selectedIndex === selectedRows.length - 1) {
    newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelectedRows = newSelectedRows.concat(
      selectedRows.slice(0, selectedIndex),
      selectedRows.slice(selectedIndex + 1)
    );
  }
  type==='ola' ?  setSelectedRows(newSelectedRows):setSelectedRows(newSelectedRows) ;
 
  console.log('selected rows', newSelectedRows)
};
const handleRowSelected = (row, type) => {
  const selectedRowsArray = type === 'ola' ? selectedRows : selectedRowsChant;
  const selectedIndex = selectedRowsArray.indexOf(row);
  let newSelectedRows = [];

  if (selectedIndex === -1) {
    newSelectedRows = [...selectedRowsArray, row];
  } else {
    newSelectedRows = selectedRowsArray.filter((selectedRow) => selectedRow !== row);
  }

  if (type === 'ola') {
    setSelectedRows(newSelectedRows);
  } else {
    setSelectedRowsChant(newSelectedRows);
  }

  console.log('selected rows', newSelectedRows);
};

  const columns = [
    {
      selector:(row) => row?._id,
      name: "",
      width: '5%',
      cell: (row) => (
        <div>
        {row?.status==="toSend" ? (
           <input
            type="checkbox"
            className="form-check-input"
            checked={selectedRows.includes(row?._id) || selectedRowsChant.includes(row?._id) }
            onChange={() => handleRowSelected(row?._id, row?.type)}
          />
        )
        :''} 
         
        </div>
      ),
    },
    {
      selector:(row) => row?.name,
      name: "Nom de l'animation",
      sortable: false,
    },
    {
      selector: (row) => row?.type,
      name: "Type",
      sortable: true,
     
    },
    {
      selector:(row) => row?.accesNiveau,
      name: "Zone",
      sortable: false,
    
     // format: (row) => new Date(row.date).toLocaleDateString(),
    },
    
    {
      selector: (row) => row?.heureDeb,
      name: "Programmée à",
      sortable: false,
      
    },
   
    {
      name: "Statut",
      selector:  (row) => row?.status,
      sortable: true,

      cell: (row) => {
        if (row.status === "toSend") {
          return <span className="text-venir">À envoyer</span>;
        }
        if (row.status === "sended") {
          return <span className="text-success">Envoyée</span>;
        }
       
      },
      
    },
    {
      name: "",
      cell: (row) => {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">
          

            <button isrtl={true}
              type="button"
              className="btn btn-link"
               onClick={() => {
                setIdToDeleteAnim(row._id);
                  setType(row?.type)
                  setIsOpenDeleteAnimation(true);
              }}
            >
              <img src={trash} alt="trash icon" />
            </button>
            <a className="btn btn-link" href={row?.type === 'ola' ? `up/${row._id}` : `chant/${row._id}`} type="button">
             <img src={edit} alt="edit" />
          </a>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  function getEvent() {
    console.log("id event", params.id);
    Services.getOne(params.id, "event/getOne")
      .then((response) => {
        console.log(response);
        setEvent(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getMessage() {
    console.log("id event", params.id);
    Services.getOne(params.id, "message/getAllByEvent")
      .then((response) => {
        console.log('messages', response);
        setMessages(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getAnimation() {
  const data={
      event:params?.id
    }
    console.log("id event", params.id);
    Services.getAllByData("animation/getByEventId",data)
      .then((response) => {
        console.log("animation",response?.data);
        const sortedData = response?.data.sort((a, b) => {
          const dateA = new Date(a.dateTime);
          const dateB = new Date(b.dateTime);
          return dateB - dateA;
        });
        setAnimations(sortedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const HandleSend = (data, data2) => {
    console.log('data' , data, 'data2', data2);
    const toUpdated={data, data2}
    Services.create(toUpdated, 'animation/updateStatus').then((res)=>{
      window.location.reload();
      console.log(res);
    })
    .catch((err)=>{
      console.log(err);
    })
    console.log('data', data)
  };
  const deleteMessage=(id)=>{
    console.log('deleteMessage all', id)
    setIsOpenDelete(true)
    setIdTodelete(id)
    
    }
   const DeleteMessage=()=>{ 
    console.log('delete event all')
    Services.deleteByid(idTodelete,'message/delete')
    .then((res)=>{
      console.log('res', res)
    })
    .catch((err)=>{
      console.log(err)
    })
   
     
    }
    const DeleteAnimation=()=>{ 
    console.log('delete aniamation all', idToDeletedAnim)
    let endPoint='';
    if(type==='ola'){
     endPoint='up/delete'
    }else{
      endPoint='chant/delete'
    }
    Services.deleteByid(idToDeletedAnim,endPoint)
    .then((res)=>{
      console.log('res', res)
    })
    .catch((err)=>{
      console.log(err)
    })
   
     
    }
  useEffect(() => {
    getEvent();
    getAnimation();
    getMessage();
  }, [isOpen,isOpenDelete, isOpenDeleteAnimation]);

  return (
    <>
      {isOpen ? <ModalAnimation isOpen={isOpen} setIsOpen={setIsOpen} /> : ""}
      {isOpenDelete  ? <DeleteModal 
      isOpenDelete={isOpenDelete} 
      setIsOpenDelete={setIsOpenDelete} 
      hideModal={()=>{setIsOpenDelete(false)}} 
       DeleteEvent={DeleteMessage} 
    title="Souhaitez-vous supprimer ce message ? "/>:""}
    {isOpenDeleteAnimation? <DeleteModal 
      isOpenDelete={isOpenDeleteAnimation} 
      setIsOpenDelete={setIsOpenDeleteAnimation} 
      hideModal={()=>{setIsOpenDeleteAnimation(false)}} 
       DeleteEvent={DeleteAnimation} 
    title="Souhaitez-vous supprimer l'animation ? "/>:""}
      <Layout>
        <div className="col  bg-fond">
          <div className="row">
            <div className="m-vh-100  ">
              <div className="container p-40">
                <div className="bread-crumb">
                  <span>Évènements</span>
                  <img src={chevron} alt="chevron"></img>
                 <span className="bread-item">{event.title}{" "}</span> 
                </div>
                <div className="retour pt-26 mb-20">
                  <img src={iconBack} alt="iconBack"></img>
                  <a href='/dashboard/events/' className="retour-link">Retour</a>
                </div>
                <div className="row">
                 <div className="col-md-5">
                  <div className="w-fitcontent">
                  <h1 className="d-title">{event.title}{" "}  <span className="border-title"></span></h1>{" "} </div> 
                      </div>
                 <div className="col-md-7 text-right">
                  <button isrtl={true}
                    className="btn-primary-up mr-20 "
                    onClick={HandleScheduleAnimation}
                  >
                    Programmer une animation
                  </button>
                  <a
                  
                   href={`/dashboard/events/message/${params.id}`}
                  >
                  <button isrtl={true}  className="btn-secondary-up mt-sm-2 ">Envoyer un message aux supporters</button>  
                  </a></div>
                </div>
          
                <div className="pt-20">
                  <span className="pr-20 typo">
                    {moment(event?.date)?.format("DD/MM/YYYY")}
                  </span>
                  <span>
                    {event?.startTime?.replace(':','h')}-{event?.endTime?.replace(':','h')}
                  </span>
                </div>
                <div className="row pt-30">
                {messages?.slice(0, 3).map((message) => (
                  <div className="col-md-3">
                    <Card data={message} deleteMessage={deleteMessage} />
                  </div>
                ))}
                 
                 
                 {messages?.length>0 ? (
                   <div className="col-md-3">
                  <div className="card" >
                  <div className="card-body all">
                 < a href={`/dashboard/events/messages/${params.id}`}>Voir tous les messages</a>
                  </div>
                </div>
                  </div>
                 )  :''}
                </div>
                <div className="space-72"></div>
              
              </div>
              <div className="container animations">
              {animations && 
                <
                  Datatables
                  data={animations}
                  columns={columns}
                  title={'Mes animations '}
                  path={''}
                  noDataText={'Aucune animation programmée pour le moment'}
                
                />
          } 
           <button isrtl={true} className='btn-primary-up right mt-20 mb-30' disabled={selectedRows?.length <= 0 && selectedRowsChant?.length<=0} onClick={()=>HandleSend(selectedRows, selectedRowsChant)}>Envoyer aux Supporter</button>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DetailEvent;
