import React from 'react';
import { Route, Routes, BrowserRouter, redirect  } from "react-router-dom";
import './App.css';
import Login from './views/auth/login';
import AuthService from './services/auth.service';
import ResetPassword from './views/auth/resetpassword';
import ChangePassword from './views/auth/changepassword';
import PrivateRoute from './hooks/PrivateRoute';
import Events from './views/dashborad/events';
import StadiumCanvas from './components/stadiumCanvas';
import Models from './views/dashborad/models';
import DetailEvent from './views/dashborad/detailsEvent';
import PrivateToAdmin from './hooks/PrivateToAdmin';
import Users from './views/EspaceAdmin/users/listUsers';
import Animateurs from './views/EspaceAdmin/animateurs/listAnimateurs';
import AddAnimateur from './views/EspaceAdmin/animateurs/add';
import Stades from './views/EspaceAdmin/stades/listStades';
import EditAnimateur from './views/EspaceAdmin/animateurs/update';
import Params from './views/dashborad/params';
import DetailsAnimateur from './views/EspaceAdmin/animateurs/details';
import StadiumCanvasChant from './components/stadiumCanvasChant';
import StadiumCanvasMessage from './components/stadiumCanvasMessage';
import AllMessages from './views/dashborad/allMessages';
import EditStadiumCanvas from './components/stadiumCanvasEdit';
import EditStadiumCanvasChant from './components/EditstadiumCanvasChant';
export const UserContext = React.createContext({
  token: "",
  role: "",
  id: "",
});
function App() {
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
   
  }, []);
  
  return (
    <div className="App">
        <BrowserRouter>
        <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/stadium" element={<StadiumCanvas />} />
        <Route path="/" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/change-password/:token" element={<ChangePassword />} />
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="" element={<Events />} />
          <Route path="Events" element={<Events />} />
          <Route path="Events/:id" element={<DetailEvent />} />
          <Route path="Models" element={<Models />} />
          <Route path="Events/Messages/:id" element={<AllMessages />} />
          <Route path="events/animation/up/:id" element={<StadiumCanvas />} />
          <Route path="events/up/:id" element={<EditStadiumCanvas />} />
          <Route path="events/chant/:id" element={<EditStadiumCanvasChant />} />
          <Route path="events/animation/chant/:id" element={<StadiumCanvasChant />} />
          <Route path="events/message/:id" element={<StadiumCanvasMessage />} />
          <Route path="params" element={<Params />} />  

        </Route>
        <Route path="/admin" element={<PrivateToAdmin />}>
        <Route path="" element={<Users />} />
          <Route path="users" element={<Users />} />
          <Route path="animateurs" element={<Animateurs />} />  
          <Route path="animateurs/:id" element={<DetailsAnimateur />} />  
          {/* <Route path="animateurs/edit/:id" element={<EditAnimateur />} />   */}
          {/* <Route path="animateurs/ajout" element={<AddAnimateur />} /> */}
          <Route path="stades" element={<Stades />} />
        </Route>
      </Routes>
      
    </BrowserRouter>
    </div>
  );
}

export default App;
