import React, { useState, useEffect } from 'react';

import SideBar from '../../components/sidebar';
import '../../../src/assets/css/event.css';
import trash from '../../../src/assets/img/svg/trash.svg';
import edit from '../../../src/assets/img/svg/edit.svg';
import Footer from '../../components/footer';
import '../../App.css';
import EventService from '../../services/EventService';
import Datatables from '../../components/datatables';
import Layout from '../layout';
import ModalUp from '../../components/modal';

import DeleteModal from '../../components/deleteModal';
import Services from '../../services/global.service';
import ModalEdit from '../../components/modalEdit';


function Events() {
  const [events, setEvents] = useState([]);
  const [stades, setStades] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [idToDeleted, setIdToDelete] = useState();
  const [idToEdit, setIdToEdit] = useState();
  const path="/dashboard/events/";
  const pathdelete="/event/delete/"
  const hideModal = () => {
    setIsOpen(false);
  };
  function handleDelete(id){
    console.log("id",id)
    setIdToDelete(id);
    setIsOpenDelete(true);
   
  }
  function getStades(){
    Services.getAll('stade/getAll')
    .then((response)=>
    {
     console.log('res', response);
     setStades(response?.data);
    })
    .catch((error)=>{
      console.log(error);
    });
  }
  function DeleteEvent()
  {
    Services.deleteByid(idToDeleted,'event/delete').then((res)=>{
    console.log('res', res)
    })
    .catch((err)=>{
      console.log('err', err)
    })
  }  
const handleRowClick = (row) => {
    // Redirect to the detail event page using the row id
    window.location.href = `/events/${row._id}`;
  };
  const columns = [
    {
      selector:  (row) =>row?.date,
      name: 'Date',
      sortable: true,
      format: (row) => new Date(row.date).toLocaleDateString()
    },
    {
      selector:  (row) =>row?.title,
      name: "Titre de l'évènement",
      sortable: false,
      minWidth:'20%'
      
    },
    {
      selector:  (row) =>row?.startTime,
      name: 'Heure de début',
      sortable: false,
      format: (row) => {
        if (row?.startTime) {
          return row.startTime.replace(':', 'h');
        }
        return '';
      }
    },
    {
      name: 'Heure de fin',
      selector:  (row) =>row?.endTime,
      sortable: false,
      format: (row) => {
        if (row?.endTime) {
          return row.endTime.replace(':', 'h');
        }
        return '';
      }
    },
    {
      selector:  (row) =>row?.stade.name,
      name: 'Nom du stade',
      sortable: false,
      cell: (row) => row.stade ? row.stade.name: 'N/A'   
    },
    {
      name: 'Statut',
      selector:  (row) =>row?.status,
      sortable: true,
      cell:(row)  => {
        if (row.status === 'planned') {
          return <span className="text-venir">À venir</span>;
        } 
        if (row.status === 'ongoing') {
          return <span className="warning">En cours</span>;
        }
        if (row.status === 'completed') {
          return <span className="success">Terminé</span>;
        }
      },
    },
    {
      name: "",
      cell: (row) =>
      {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">
          

            <button
              type="button"
              className='btn btn-link'
              onClick={() => {
                handleDelete(row._id);
                setIdToDelete(row._id);
              }}
             
            >
               <img src={trash} alt="trash icon" />
            </button>
            <button
              type="button"
              className='btn btn-link'
              onClick={() => {
                setIsOpenEdit(true);
                setIdToEdit(row._id);
              }}
             
            >

              <img src={edit} alt="edit"/>
            </button>

          </div>);
        
      }
      ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  
  function getEvents(){
    EventService.getAllEvents()
    .then((response)=>
    {
      setEvents(response)
    })
    .catch((error)=>{
      console.log(error);
    });
  }
  const HandleAddEvent = () => {
    setIsOpen(true);
  };

  useEffect(() => {
  getEvents();
  getStades()
  }, [isOpen,isOpenEdit,isOpenDelete]);

  return (
    <>
    {isOpen ? <ModalUp isOpen={isOpen} setIsOpen={setIsOpen} stades={stades} />:""}
    {isOpenEdit ? <ModalEdit isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} idToEdit={idToEdit} stades={stades} />:""}
    {isOpenDelete  ? <DeleteModal isOpenDelete={isOpenDelete} setIsOpenDelete={setIsOpenDelete} hideModal={hideModal} 
    DeleteEvent={DeleteEvent} 
    title="Souhaitez-vous supprimer cet évènement ?"/>:""}
      <Layout>
      <div className="col pt-3  bg-fond">
       <div className="row"> 
       <div className="m-vh-100  p-40">
       <div className="container">
       
       <div className="row">
        <div className="col-md-6"> <h1 className="d-title">évènements   <span className="border-title"></span> 
       </h1></div>
        <div className="col-md-6">  <button className='btn-primary-up right resp-btn' onClick={HandleAddEvent}>Créer un évènement</button></div></div>

      
       
        <div className="space-72"></div>
      
        
        </div>  
        <div className="container event">
         {events && 
         <
          Datatables
          data={events}
          columns={columns}
          title={'Liste des évènements'}
          path={'/dashboard/events'}
          noDataText={'Aucune événement programmée pour le moment'}
        
         />
          } 
        </div>
        </div> 
        <Footer/>
        </div>
    
        </div>
       </Layout>
    
    </>
  );
}

export default Events;
