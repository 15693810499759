import React, { useState, useEffect } from 'react';


import '../../../assets/css/event.css';
import trash from '../../../assets/img/svg/trash.svg';
import edit from '../../../assets/img/svg/edit.svg';
import Footer from '../../../components/footer';
import '../../../App.css';

import Datatables from '../../../components/datatables';
import Layout from '../../layout';
import ModalUp from '../../../components/modal';

import DeleteModal from '../../../components/deleteModal';
import Services from '../../../services/global.service';
import AddAnimateur from './add';
import EditAnimateur from './update';



function Animateurs() {
  const [users, setUsers] = useState([]);
  const [stades, setStades] = useState([]);
  const [renitial, setRenitial] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [idToDeleted, setIdToDelete] = useState('');
  const [idToEdited, setIdToEdited] = useState('');
  const path="/dashboard/users/";
  const pathdelete="/user/delete/"
  const hideModal = () => {
    setIsOpen(false);
  };
  function handleDelete(id:any){
    console.log("id",id)
    setIdToDelete(id);
    setIsOpenDelete(true);
   
  }
  function DeleteUser()
  {
    Services.deleteByid(idToDeleted,'user/delete').then((res)=>{
    console.log('res', res)
    })
    .catch((err)=>{
      console.log('err', err)
    })
  }  
const handleRowClick = (row:any) => {
    // Redirect to the detail event page using the row id
    window.location.href = `/animateurs/${row._id}`;
  };
  const columns = [
    
    {
      selector: (row: any) =>row.lastName,
      name: 'Nom',
      sortable: true
    },
    {
      selector:(row: any) => row.firstName,
      name: 'Prénom',
      sortable: false
    },
    {
      selector:(row: any) => row.email,
      name: 'Email',
      sortable: false,
      minWidth: '30%',
    },
    {
      name: 'Stade',
      selector: (row: any) => row?.stade?.name,
      sortable: false,
      cell: (row:any) => row.stade ? row.stade.name : 'N/A'
    },
    
    {
      name: "",
      cell: (row:any) =>
      {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">
        

            <button
              type="button"
              className='btn btn-link'
              onClick={() => {
                handleDelete(row._id);
                setIdToDelete(row._id);
              }}
             
            >
               <img src={trash} alt="trash icon" />
            </button>
            <button className='btn btn-link' onClick={()=>{setIsOpenEdit(true); setIdToEdited(row?._id)}} >

          <img src={edit} alt="edit"/>
          </button>
          </div>);
        
      }
      ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  
  function getAnimateurs(){
    Services.getByRole('user/','ROLE_ANIMATEUR')
    .then((response)=>
    {
     console.log('res', response);
    setUsers(response?.data);
    })
    .catch((error)=>{
      console.log(error);
    });
  }
  function getStades(){
    Services.getAll('stade/getAll')
    .then((response)=>
    {
     console.log('res', response);
     setStades(response?.data);
    })
    .catch((error)=>{
      console.log(error);
    });
  }
  const HandleAddEvent = () => {
    setIsOpen(true);
  };
  const handleRowSelect = (row:any) => {
    setRenitial(row)
    console.log('Selected Row:', row);
  };
  useEffect(() => {
    getStades()
    getAnimateurs();
  }, [isOpenDelete,isOpen]);

  return (
    <>
     {isOpen ? <AddAnimateur isOpen={isOpen} setIsOpen={setIsOpen} stades={stades} />:""} 
     {isOpenEdit ? <EditAnimateur isOpenEdit={isOpenEdit} setIsOpenEdit={setIsOpenEdit} idToEdited={idToEdited}  stades={stades} />:""} 
    {isOpenDelete  ? <DeleteModal isOpenDelete={isOpenDelete} 
    setIsOpenDelete={setIsOpenDelete} hideModal={hideModal} 
    DeleteEvent={DeleteUser} 
    title="Souhaitez-vous supprimer l'animateur ? " />:""}
      <Layout>
      <div className="col pt-3 bg-fond">
       <div className="row"> 
       <div className="m-vh-100  p-40">
       <div className="container">
       
       <div className="row"> <div className="col-md-8"><h1 className="d-title">Animateurs  <span className="border-title"></span>  
       </h1></div> <div className="col-md-4"><button className='btn-primary-up right w-100-xs' onClick={()=>{setIsOpen(true);}}>Créer un animateur</button></div> </div>
        
        <div className="space-72"></div>
      
        
        </div>  
        <div className=" event">
         {users && 
         <
          Datatables
          data={users}
          columns={columns}
          title={'Liste des animateurs'}
          path={''}
          selectable={false}
          onRowSelect={handleRowSelect}
          noDataText={'Aucune animateur trouvé'}
         />
          } 
         {/* <button className='btn-primary-up right mt-20' disabled={renitial.length <= 0}>Réinitialiser mot de passe</button> */}

        </div>
        </div> 
        <Footer/>
        </div>
    
        </div>
       </Layout>
    
    </>
  );
}

export default Animateurs;
