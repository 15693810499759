import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import closeIcon from '../assets/img/svg/close-outline.svg';
import '../assets/css/modal.css';
const DeleteModal=({isOpenDelete,setIsOpenDelete,hideModal,DeleteEvent,title})=>{
    function confirmDelete(){
        console.log('confirmed')
        DeleteEvent();
        setIsOpenDelete(false)
    }
    return ( 
    <Modal show={isOpenDelete} onHide={hideModal} className="modal-up" 
    >
    <ModalBody>
       <button className="btn btn-link right" onClick={()=>setIsOpenDelete(false)}><img src={closeIcon} alt="close icon" />  </button> 
      <h3 className='text-center'> {title}  </h3>
  <div className="row pt-20">    
   <div className="col"><button className='btn-secondary-up right' onClick={confirmDelete}>Oui</button></div> 
  <div className="col">
    <button className='btn-secondary-up ' onClick={()=>{setIsOpenDelete(false)}} >Non</button>
    </div>

  
    </div>  
    </ModalBody>

  </Modal>)
    
}
export default DeleteModal;