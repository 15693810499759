import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import '../../../assets/css/event.css';
import '../../../App.css';
import { Formik, Form, Field } from 'formik';
import closeIcon from '../../../assets/img/svg/close-outline.svg';
import Services from '../../../services/global.service';
import { useNavigate } from 'react-router-dom';



function AddAnimateur({ isOpen, setIsOpen, stades }) {

  const initialValues = {
    entreprise_equipe: '',
    firstName:'',
    lastName:'',
    email: '',
    stade:'',
    // password: '',
    // confirmPassword: '',
  };
  const [message, setMessage]=useState('')
  const navigate=useNavigate();
  const validate = (values) => {
    const errors = {};
    if (!values.firstName ) {
      errors.firstName = 'Champs requis';
    }
    if (!values.lastName ) {
      errors.lastName = 'Champs requis';
    }
    if (!values.entreprise_equipe ) {
      errors.entreprise_equipe = 'Champs requis';
    }
    if (!values.stade ) {
      errors.stade = 'Champs requis';
    }
    if (!values.email) {
      errors.email = 'Champs requis';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Adresse email Invalid ';
    }
   
    return errors;
  };
 
 
  const onSubmit = (values) => {
    // submit form data here
    console.log(values);
    Services.create(values,'user/createAnimateur').then((res)=>{
      console.log('res',res)
      setTimeout(()=>{ setIsOpen(false);},1000);
    
    })
    .catch((err)=>{
     setMessage(err?.response?.data?.message)
      console.log('err',err);
    })
  };

  
 const hideModal = () => {
    setIsOpen(false);
  };
  return (
   
    <Modal show={isOpen} onHide={hideModal} className="modal-up">
      <Modal.Header>
        <Modal.Title>
        
           <h3>Créer un animateur</h3> 
        
      
     </Modal.Title>  
   <button className="btn btn-link" onClick={()=>setIsOpen(false)}><img src={closeIcon} alt="close icon" />  </button> 
      </Modal.Header>
      <Modal.Body>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <span className='text-danger py-3'>{message}</span>
            <div className="row">
            <div className='col-md-6'>
              <label htmlFor="entreprise_equipe">Entreprise/Equipe</label>
              <Field type="text" name="entreprise_equipe" className="form-control" />
              {errors.entreprise_equipe && touched.entreprise_equipe && <div className='text-danger'>{errors.entreprise_equipe}</div>}
            </div>
            <div className='col-md-6'>
              <label htmlFor="email">Email</label>
              <Field type="email" name="email" className="form-control"/>
              {errors.email && touched.email && <div className='text-danger'>{errors.email}</div>}
            </div>
            </div>
            <div className="row mt-30">
            <div className='col-md-6 '>
              <label htmlFor="firstName">Nom</label>
              <Field type="text" name="firstName" className="form-control" />
              {errors.firstName && touched.firstName && <div className='text-danger'>{errors.firstName}</div>}
            </div>
            <div className='col-md-6'>
              <label htmlFor="lastName">Prénom</label>
              <Field type="text" name="lastName" className="form-control"/>
              {errors.lastName && touched.lastName && <div className='text-danger'>{errors.lastName}</div>}
            </div>
            <div className='col-md-6 mt-30'>
              <label htmlFor="stade">Stade</label>
              <Field 
                name="stade" 
                component="select" 
                id="stade" 
                className="form-select"
              ><option value="">Sélectionner un stade</option>
                {stades.map((stade)=>
                 <option value={stade?._id} >{stade?.name}</option>
                )}
                 
              
              </Field>
              {errors.stade && touched.stade && <div className='text-danger'>{errors.stade}</div>}
            </div>
            </div>
            {/* <div className="row">
            <div className='col-md-6'>
              <label htmlFor="password">Password</label>
              <Field type="password" name="password" className="form-control"/>
              {errors.password && touched.password && <div className='text-danger'>{errors.password}</div>}
            </div>
            <div className='col-md-6'>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <Field type="password" name="confirmPassword" className="form-control"/>
              {errors.confirmPassword && touched.confirmPassword && <div className='text-danger'>{errors.confirmPassword}</div>}
            </div>
            </div> */}
            <button type="submit" className="btn-primary-up right mt-5">Ajouter</button>
          </Form>
        )}
      </Formik>
   </Modal.Body>
    </Modal>
  );
}

export default AddAnimateur;
