import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form } from 'formik';
import moment from "moment";
import * as Yup from 'yup';
import '../assets/css/modal.css'
import Services from "../services/global.service";
import closeIcon from '../assets/img/svg/close-outline.svg';
import { useEffect, useState } from "react";


const ModalEdit = ({ isOpen, setIsOpen ,idToEdit, stades}) => {
  const [details, setDetails]= useState(null);
  const hideModal = () => {
    setIsOpen(false);
  };
  function getDetails(){
    
    Services.getOne(idToEdit,'event/getOne')
    .then((res)=>{
      console.log(res?.data);
      setDetails(res?.data);
    })
    .catch((err)=>{

    })
    
   
   }
   
   const initialValues  = {
    date:  moment(details?.date).format('YYYY-MM-DD') ||'',
    stade: details?.stade || '',
    title: details?.title || '',
    startTime: details?.startTime || '',
    endTime: details?.endTime || '',

  };
  
  const validationSchema = Yup.object().shape({
    date: Yup.date().required('Champs requis'),
    title: Yup.string().required('Champs requis'),
    startTime: Yup.string().required('Champs requis'),
    endTime: Yup.string().required('Champs requis'),
    stade: Yup.string().required('Champs requis'),
  });
  
  const handleSubmit = (values) => {
    console.log("values", values);

    Services.update(values,idToEdit, "event").then(
      (response) => {
        console.log(response)
        setTimeout(()=>{ setIsOpen(false);},1000);
       
      },
      (error) => {
        console.log(error)
      }
    );
  };
  useEffect(()=>{
    getDetails();
   },[idToEdit]);
  return (
    <Modal show={isOpen} onHide={hideModal} className="modal-up">
      <Modal.Header>
        <Modal.Title>
        
           <h3>Modifier évènement</h3> 
        
      
     </Modal.Title>  
   <button className="btn btn-link" onClick={()=>setIsOpen(false)}><img src={closeIcon} alt="close icon" />  </button> 
      </Modal.Header>
      <Modal.Body>
        {details ?
(


        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ errors, touched, values }) => (
            <Form>
              <div className="row">
                <div className="col-6 mb-3">
                  <label htmlFor="date">Date</label>
                  <Field type="date" name="date" id="date"  className="form-control" />
                  {errors.date && touched.date ? <div>{errors.date}</div> : null}
                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="title">Titre de l’évènement</label>
                  <Field type="text" name="title" id="title"  className="form-control"  />
                  {errors.title && touched.title ? <div>{errors.title}</div> : null}
                </div>
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  <label htmlFor="startTime">Heure de début</label>
                  <Field type="time" name="startTime" id="startTime"   className="form-control" />
                  {errors.startTime && touched.startTime ? <div>{errors.startTime}</div> : null}
                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="endTime">Heure de fin</label>
                  <Field type="time" name="endTime" id="endTime"   className="form-control" min={values.startTime}  />
                  {errors.endTime && touched.endTime ? <div>{errors.endTime}</div> : null}
                </div>
              </div>
              <div className="row">
                
                <div className='col-6'>
              <label htmlFor="lastName">Stade</label>
              <Field 
                name="stade" 
                component="select" 
                id="stade" 
                className="form-select"
              >
                <option value="">Sélectionner un stade</option>
                {stades.map((stade)=>
                 <option value={stade?._id} selected={stade?._id===details?.stade}>{stade?.name}</option>
                )}
                 
              </Field>
              {errors.stade && touched.stade && <div className='text-danger'>{errors.stade}</div>}
            </div>
              </div>
              <div className="modal-footer">
                <button type="submit"  className="btn-primary-up" >Sauvegarder</button>
              </div>
            </Form>
          )}
        </Formik>
        )
        :""
      }
      </Modal.Body>
    </Modal>
  );
}

export default ModalEdit;
