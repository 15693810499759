import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import '../assets/css/modal.css'; 
import closeIcon from '../assets/img/svg/close-outline.svg';
import { useNavigate } from 'react-router-dom';

const ConfirmModal = ({ openConfirm, setOpenConfirm, title, event }) => {
 // console.log('redirect', redirect)
  const navigate = useNavigate();

  const handleNavigate = () => {
    console.log('path', )
    if (navigate) {
      window.location.reload();
    }
  };

  return ( 
    <Modal show={openConfirm} onHide={() => setOpenConfirm(false)} className="modal-up">
      {/* <ModalHeader></ModalHeader> */}
      <ModalBody>
        <h3 className='text-center'> {title} 
          <button className="btn btn-link right" onClick={() => setOpenConfirm(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </h3> 

        <div className="row pt-20">  
          <div className="col">
            <button className='btn-primary-up right' onClick={() => handleNavigate()}>
              Créer un nouveau
            </button>
          </div>
          <div className="col">
            <button className='btn-secondary-up' onClick={() => navigate(`/dashboard/events/${event}`)}>
              Retour à l’évènement
            </button>
          </div>
        </div>  
      </ModalBody>
    </Modal>
  );
}

export default ConfirmModal;
