import React, { useState } from 'react';
import '../../assets/css/login.css';
import AuthService from '../../services/auth.service';


const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState({ message: '', visible: false, error:false });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevents page reload

    AuthService.resetPassword(email)
      .then((response) => {
        console.log('res',response);
        // if(response?.response?.status===400){
        //   setNotification({ message: response?.response?.data?.message, visible: true, error:true }); 
        // }else{}
            setNotification({ message: 'Un lien a bien été envoyé par email', visible: true, error:false }); 
            return;
          
      })
      .catch((error) => {
        setNotification({ message: 'Error resetting password.', visible: true, error:true });
        console.log(error);
      });
  };

  return (
    <div className="login">
      <div className="form-login">
      {notification.visible && (
          <div className="notification">
            <span className={notification.error ?"text-danger py-2":"text-success py-2"}>{notification.message}</span>
          </div>
        )}
        <h2 className='title-reset'>Réinitialiser le mot de passe</h2>
        
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username" className='label-login'>Email</label>
          </div>
          <div>
            <input
              className="form-control"
              type="email"
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder='Adresse email'
            />
          </div>
          <button type="submit" className='btn btn-primary'>Envoyer</button>
        </form>
       
      </div>
    </div>
  );
};


export default ResetPassword;
