import React, { useState } from 'react';
import '../../assets/css/login.css';
import AuthService from '../../services/auth.service';
import { useParams } from 'react-router-dom';
import Eye from '../../assets/img/svg/icon _eye outline_.svg'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setnewPassword] = useState('');
  const [confirmnewPassword, setconfirmnewPassword] = useState('');
  const [message, setMessage] = useState('');
  const  token  = useParams();
  const navigate = useNavigate();
  const handleToggle = (type) =>()=> {
    if(type==="new"){
       setShowPassword(!showPassword);
    }
    if(type==="confirm"){
      setShowConfirmPassword(!showconfirmPassword);
   }
  // type==='new' ? setShowPassword(!showPassword) :setShowConfirmPassword(!showconfirmPassword);
   
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (newPassword !== confirmnewPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    console.log('toekn and new pass ', token.token,"********************" ,newPassword);

    AuthService.changePassword(token.token,newPassword).then(
      (Response)=>{
        console.log(Response)
       
       if (Response.response && Response.response.status === 401) {
          console.log('token not valide')
          setMessage(Response.response.data.message);
          return;
        } else{
          toast.success('Mot de passe modifier avec succès !', {
            autoClose: 3000,
            onClose:  navigate('/login'), 
          });
          //setMessage(Response.response.data.message);
          navigate('/login')
          
        }
        
      },
      (error)=>{
       
        setMessage(error);
      }
    );
    
  }

  return (
    <div className="login">
      <ToastContainer />
        <div className="form-login">
        <p>{message}</p>
            <h2 className='title-reset'>Réinitialiser le mot de passe</h2>
    <form onSubmit={handleSubmit}>
      <div className="password-field">
        <label htmlFor="username" className='label-login'>Nouveau mot de passe</label></div><div>
      <div className="password-input-container">  <input
        className="form-control"
        type={showPassword ? 'text' : 'password'}
          id="password"
          value={newPassword}
          onChange={(event) => setnewPassword(event.target.value)}
          placeholder='new password'
        />
        {showPassword ? (
          <img src={Eye} className="password-icon" alt ="password show" onClick={handleToggle('new')} />
        ) : (
          <img src={Eye} className="password-icon" alt ="password-hide" onClick={handleToggle('new')} />
        )}</div>
      </div>
      <div className="password-field mt-3">
        <label htmlFor="username" className='label-login'>Confirmer le nouveau mot de passe</label></div><div>
        <div className="password-input-container"> <input
        className="form-control"
        type={showconfirmPassword ? 'text' : 'password'}
          id="newpassword"
          value={confirmnewPassword}
          onChange={(event) => setconfirmnewPassword(event.target.value)}
          placeholder='confirm password'
        />
          {showconfirmPassword ? (
          <img src={Eye} className="password-icon" alt ="password show" onClick={handleToggle('confirm')} />
        ) : (
          <img src={Eye} className="password-icon" alt ="password-hide" onClick={handleToggle('confirm')} />
        )}</div>
      </div>
     
      <button type="submit" className='btn btn-primary '>Valider</button>
    </form>
    </div>
    </div>
  );
}

export default ChangePassword;
