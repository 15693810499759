import DataTable from 'react-data-table-component';
import '../App.css';
import filterIcon from'../assets/img/svg/funnel-outline.svg'
import { useMemo, useState } from 'react';
export default function  Datatables({data, columns, title,path, selectable, onRowSelect,noDataText})
{
  const [filterText, setFilterText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

    console.log("data",data)
    const customStyles = {
        table: {
            style: {
              backgroundColor: '#FAFAFA',
              border:"none",
              fontSize:"16px",
             
            },
          },
        headCells: {
          style: {
            backgroundColor: '#FAFAFA',
            borderBottomStyle:"hidden",
            fontSize:"16px",
            fontFamily:'Inter',
            fontWeight:'600',
          //  marginLeft:'10px',

          }
        },
        rows: {
            style: {
                backgroundColor:"#fff",
                borderRadius:"20px",
                margin:"10px",
                width:'98%',
                boxShadow:"0px 4px 20px rgba(0, 0, 0, 0.1)",
                borderBottomStyle:"none",
                fontSize:"16px",
                fontFamily:'Inter',
                minHeight:'64px'
            },
          },
          checkbox: {
            style: {
                height: '18px',
                width: '18px',
                border:'red'
            },
        },
        
      }
     
    const paginationComponentOptions = {
        rowsPerPageText: 'Lignes par page',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Tous',
    };
    const handleRowClick = (row) => {
      // Redirect to the detail event page using the row id
      window.location.href = `${path}/${row._id}`;
    };
    const handlecheckClick = (row) => {
      const isSelected = selectedRows.includes(row);
      setSelectedRows(
        isSelected
          ? selectedRows.filter((r) => r !== row)
          : [...selectedRows, row]
      );
    };
    const filteredItems = data?.filter(
      item =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
    );
    const subHeaderComponent = useMemo(() => {
      const handleClear = () => {
        if (filterText) {
         
          setFilterText("");
        }
      };
  
      return (
       <>
        <div className='col-md-8'><h2 className="">{title}</h2>  </div> 
        <div className='col-md-4'>
        <input
        className=' form-control  '
        id="search"
        type="text"
        placeholder="Recherche"
        value={filterText}
        onChange={e => setFilterText(e.target.value)}
      /></div></>
        // <FilterComponent
        //   onFilter={e => setFilterText(e.target.value)}
        //   onClear={handleClear}
        //   filterText={filterText}
        // />
      );
    }, [filterText]);
    const handleSelectedRowsChange = (selectedRows) => {
      // Extract the row indexes from the selected rows
      const selectedIndexes = selectedRows.selectedRows.map((row) => row._id);
    
      setSelectedRows(selectedIndexes);
    
      // Invoke the onRowSelect prop with the selected row data
      if (onRowSelect) {
       
        const selectedData = selectedIndexes?.map((index) => index
        );
        onRowSelect(selectedData);
      }
    };
   
return(<>

    <DataTable
    columns={columns}
    data={filteredItems}
    className="datatable-all"
    noDataComponent={<div className="p-3">{noDataText}</div>}
    pagination
    paginationComponentOptions={paginationComponentOptions}
    customStyles={customStyles}
    onRowClicked={(row) => {  
      if(path!==''){
         handleRowClick(row); 
      }
     
     }}
      subHeader
    subHeaderComponent={subHeaderComponent}
    selectableRows={selectable}
    onSelectedRowsChange={handleSelectedRowsChange}
    />
</>)
}