import SideBar from "../components/sidebar";

function Layout(props:any){
return(
    <div className="container-fluid">
    <div className="row flex-nowrap"> 
    <SideBar />
        {props.children}
    </div>
    </div>
)
}
export default Layout;