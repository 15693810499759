import React, { useState } from 'react';
import '../../assets/css/login.css';
import AuthService from '../../services/auth.service';
import Eye from '../../assets/img/svg/icon _eye outline_.svg'
import { useNavigate } from 'react-router-dom';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    AuthService.login(email, password).then(
      (Response) => {
        console.log('res', Response);
        localStorage.getItem('role') === "ROLE_ADMIN" ? navigate('/admin/animateurs') : navigate('/dashboard/events');
      },
      (error) => {
        if (error.response && error.response.status === 400) {
          console.log('password not valide', error.response.data.message);
          setError(error.response.data.message);
          return;
        }
        if (error.response && error.response.status === 404) {
          console.log('user not found');
          setError('user not found');
          return;
        }
        console.log('err', error);
        // Réactiver le bouton de connexion si l'erreur est due à un mot de passe incorrect
        if (error.response && error.response.status === 401) {
        // Réinitialiser l'erreur
        }
      }
    );
  };
  
  return (
    <div className="login">
        <div className="form-login">
            <h1 className='title-login'>Se connecter</h1>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="username" className='label-login'>Email</label></div><div>
        <input
        className="form-control"
          type="email"
          id="email"
          value={email}
          onChange={(event) => {setEmail(event.target.value);setError('');}}
          placeholder='Adresse email'
        />
      </div>
      <div className="password-field mt-20">
      <label htmlFor="password">Mot de passe</label></div>
       <div  className="password-input-container">   
        <input
         className="form-control password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          value={password}
          onChange={(event) => {setPassword(event.target.value);   setError('');}}
          placeholder='Mot de passe'
        />
         {showPassword ? (
          <img src={Eye} className="password-icon" alt ="password show" onClick={handleToggle} />
        ) : (
          <img src={Eye} className="password-icon" alt ="password-hide" onClick={handleToggle} />
        )}
       
      </div> 
      
      <div className='float-right pt-10'><a href="/reset-password" className='link'>Mot de passe oublié</a></div>
      <button type="submit" className='btn-primary-up w-100 mt-20'>Se connecter</button>
      <span className='text-danger pt-5'>{error}</span>
    </form>
    </div>
    </div>
  );
}

export default Login;
