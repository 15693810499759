import axios from "axios";
const API_URL = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem('token')

const headersFiles = {
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  };
  const getAllEvents = () => {
    return axios
      .get(API_URL + "event/getAll")
      .then((response) => {
        return response.data;
      })
      .catch((err) => { 
        console.log("erreur getEvents", err);
        return err;
       
      });
  };

 
 
  const EventService = {
   getAllEvents
   
  };
  
  export default EventService;