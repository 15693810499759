import axios from "axios";
const API_URL = process.env.REACT_APP_BASE_URL || "";

const token = localStorage.getItem('token')

const headersFiles = {
    headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    },
};
 
const getAll = (endpoint: string | number) => {
    return axios.get(API_URL + endpoint, headersFiles);
};
const getAllByData = (endpoint: string | number,data: any) => {
    
    return axios.post(API_URL + endpoint,data, headersFiles);
};
const getOne = (id: any, endpoint: string | number) => {
    return axios.get(API_URL +  endpoint + `/${id}`, headersFiles);
};

const create = (data: any, endpoint: string | number) => {
    return axios.post(API_URL +  endpoint, data, headersFiles);
};

const deleteByid = (id: any, endpoint: string | number) => {
    return axios.delete(API_URL + endpoint + `/${id}`, headersFiles);
};

const update = (data: any, id: any, endpoint: string | number) => {
    return axios.post(API_URL + endpoint + `/update/${id}`, data, headersFiles);
};

const updatewithoutID = (data: any, endpoint: string | number) => {
    return axios.post(API_URL + endpoint + `/update`, data, headersFiles);
};
const getByRole = (endpoint: string | number, role: any) => {
    
    return axios.get(API_URL + endpoint+ `/${role}`, headersFiles);
};


const Services = {

    getAll,getOne,create,deleteByid,update, updatewithoutID,getByRole,getAllByData

}
export default Services;
