import '../assets/css/footer.css';
function Footer () {
    return(
        <div className="footer">
            <a href="/" className='item-footer'>Mentions légales</a>
            <a href="/"  className='item-footer'>Conditions générales d'utilisation</a>
            <a href="/"  >Contact</a> 
        </div>
    )
}
export default Footer;
