import React, { useState, useEffect } from 'react';


import '../../../assets/css/event.css';
import trash from '../../../assets/img/svg/trash.svg';
import edit from '../../../assets/img/svg/edit.svg';
import Footer from '../../../components/footer';
import '../../../App.css';
import filterIcon from'../../../assets/img/svg/funnel-outline.svg'
import Datatables from '../../../components/datatables';
import Layout from '../../layout';
import ModalUp from '../../../components/modal';
import moment from "moment";
import DeleteModal from '../../../components/deleteModal';
import Services from '../../../services/global.service';



function Users() {
  const [users, setUsers] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [idToDeleted, setIdToDelete] = useState('');
  const hideModal = () => {
    setIsOpenDelete(false);
  };
  //open the modal and save the id user to delete
  function handleDelete(id:any){
    console.log("id",id)
    setIdToDelete(id);
    setIsOpenDelete(true);
   
  }
  function DeleteUser()
  {
    //delete user id user To delete (idToDeleted)
    Services.deleteByid(idToDeleted,'user/delete').then((res)=>{
    console.log('res', res)
    })
    .catch((err)=>{
      console.log('err', err)
    })
  }  

  const columns = [
   
    {
      selector: 'email',
      name: 'Email',
      sortable: true,
      sortIcon: <img src={filterIcon} alt="Sort" />,
    },
   
    
    {
      name: 'Date de dernière connexion',
      selector: 'date_last_cnx ',
      format: (row:any) => new Date(row.date_last_cnx).toLocaleDateString(),
      sortable: true
    },
    
    {
      name: "",
      cell: (row:any) =>
      {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">

            <button
              type="button"
              className='btn btn-link'
              onClick={() => {
                handleDelete(row._id);
                setIdToDelete(row._id);
              }}
             
            >
               <img src={trash} alt="trash icon" />
            </button>

          </div>);
        
      }
      ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  
  function getUsers(){
    /*get user with role=== role_admin**/
    Services.getByRole('user/','ROLE_USER')
    .then((response)=>
    {
     console.log('res', response);
    setUsers(response?.data);
    })
    .catch((error)=>{
      console.log(error);
    });
  }
  

  useEffect(() => {
  getUsers();
  }, [isOpenDelete]);

  return (
    <>
  
    {isOpenDelete  ? <DeleteModal isOpenDelete={isOpenDelete} setIsOpenDelete={setIsOpenDelete} hideModal={hideModal} DeleteEvent={DeleteUser}
    title="Souhaitez-vous supprimer cet utilisateur ? "/>:""}
      <Layout>
      <div className="col pt-3 bg-fond">
       <div className="row"> 
       <div className="m-vh-100  p-40">
       <div className="container">
       
       <div className="d-flex justify-content-between"><p className="d-title">Liste Utilisateurs    <span className="border-title"></span> 
       </p> </div>
       
        <div className="space-72"></div>
      
        
        </div>  
        <div className="container event">
         {users && 
         <
          Datatables
          data={users}
          columns={columns}
          title={'Liste des utilisateurs'}
          path={'/dashboard/users'}
          selectable={false}
          onRowSelect
          noDataText={'Aucune utilisaateur trouvé'}
         />
          } 
        </div>
        </div> 
        <Footer/>
        </div>
    
        </div>
       </Layout>
    
    </>
  );
}

export default Users;
