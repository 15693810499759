import React, { useState, useEffect } from "react";

import "../../../src/assets/css/event.css";
import Eye from '../../assets/img/svg/icon _eye outline_.svg';
import Footer from "../../components/footer";
import Layout from "../layout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/auth.service";
function Params() {
    const initialValues = {
        actualPassword: "",
        newPassword: "",
        confirmPassword: "",
      };  
      const [showPassword, setShowPassword] = useState(false);
      const [showNewPassword, setShowNewPassword] = useState(false);
      const [showconfirmPassword, setShowConfirmPassword] = useState(false);
  const validationSchema = Yup.object({
    actualPassword: Yup.string().required("Champs requis")
    .notOneOf([Yup.ref("newPassword")], "Le mot de passe réel doit être différent du nouveau mot de passe"),
    newPassword: Yup.string()
      .required("Champs requis")
      .min(8, "Le mot de passe doit comporter au moins 8 caractères"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null], "Les mots de passe doivent correspondre"),
  });
  const onSubmit = (values) => {
    AuthService.PasswordChange(values)
    .then((res)=>{
      if(res?.response?.status===401){
        toast.error(res?.response?.data?.message);
      }
       else{
         toast.success("Votre mot de passe à été changé avec succès ");
       }
        console.log(res)
    })
    .catch((err)=>{
        toast.error('error')
    })
    
  };
  const handleToggle = (type) =>()=> {
    console.log(type)
    if(type==="old"){
       setShowPassword(!showPassword);
    }
    if(type==="confirm"){
      setShowConfirmPassword(!showconfirmPassword);
   }

   if(type==="new"){
    console.log('here')
    setShowNewPassword(!showNewPassword);
 }   // type==='new' ? setShowPassword(!showPassword) :setShowConfirmPassword(!showconfirmPassword);
   
  };

  return (
    <Layout>
      
      <div className="col pt-3 bg-fond"> 
       <ToastContainer />
        <div className="row">
          <div className="m-vh-100  p-40">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <p className="d-title">Paramètres  <span className="border-title"></span></p>{" "}
                
                </div>
                <div className="col">
                
                </div>
              </div>
              <div className="space-72"></div>
              <h2>Modifier mon mot de passe</h2>
              <Formik initialValues={initialValues} onSubmit={onSubmit}  validationSchema={validationSchema}>
                <Form>
                  <div className="row mt-2">
                    <div className="col-md-6 password-field">
                      <label htmlFor="pseudo">Ancien mot de passe</label>
                    <div className="password-input-container"> 
                    <Field
                        name="actualPassword"
                        className="form-control"
                         type={showPassword ? 'text' : 'password'}
                        placeholder="Renseignez votre mot de passe"
                       
                      />
                       {showPassword ? (
                        <img src={Eye} className="password-icon" alt ="password show" onClick={handleToggle('old')} />
                        ) : (
                        <img src={Eye} className="password-icon" alt ="password-hide" onClick={handleToggle('old')} />
                        )}
                     
                    </div>  <ErrorMessage name="actualPassword" component="div" className="text-danger" />
                    </div> 
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6 password-field">
                      <label htmlFor="pseudo">Nouveau mot de passe</label>{" "}
                      <div className="password-input-container"> 
                      <Field
                        name="newPassword"
                        className="form-control"
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder="Renseignez votre mot de passe"
                      
                      />
                      {showNewPassword ? (
                        <img src={Eye} className="password-icon" alt ="password show" onClick={handleToggle('new')} />
                        ) : (
                        <img src={Eye} className="password-icon" alt ="password-hide" onClick={handleToggle('new')} />
                        )}
                     
                       </div>  <ErrorMessage name="newPassword" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6 password-field">
                      <label htmlFor="pseudo">Confirmez votre mot de passe</label>
                      <div className="password-input-container"> 
                      <Field
                        name="confirmPassword"
                        className="form-control"
                        type={showconfirmPassword ? 'text' : 'password'}
                        placeholder="Confirmez votre mot de passe"
                      
                      />
                       {showconfirmPassword ? (
                        <img src={Eye} className="password-icon" alt ="password show" onClick={handleToggle('confirm')} />
                        ) : (
                        <img src={Eye} className="password-icon" alt ="password-hide" onClick={handleToggle('confirm')} />
                        )}
                      
                       </div> <ErrorMessage name="confirmPassword" component="div" className="text-danger"/>
                    </div>
                  </div>
                  <button type="submit" className="btn-primary-up mt-2">
                   Valider
                  </button>
                </Form>
              </Formik>
            </div>{" "}
          </div>{" "}
          <Footer />
        </div>
      </div>
    </Layout>
  );
}

export default Params;
