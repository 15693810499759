import React, { useState, useEffect } from 'react';
import '../../../assets/css/event.css';
import Footer from '../../../components/footer';
import '../../../App.css';
import { Formik, Form, Field } from 'formik';
import Layout from '../../layout';
import Modal from "react-bootstrap/Modal";
import Services from '../../../services/global.service';
import { useParams } from 'react-router';
import closeIcon from '../../../assets/img/svg/close-outline.svg';


function EditAnimateur({isOpenEdit, setIsOpenEdit, idToEdited, stades}) {

    const [animateur, setAnimateur]=useState(null);
    
    const getAnimateurDetails=()=>{
        Services.getOne(idToEdited,'user/get')
        .then((res)=>{
            console.log(res);
            setAnimateur(res?.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    useEffect(()=>{
        getAnimateurDetails();
      },[]);

  const initialValues = {
    stade: animateur?.stade || '',
    entreprise_equipe: animateur?.entreprise_equipe || '',
    firstName:animateur?.firstName ||'',
    lastName:animateur?.lastName ||'',
    email: animateur?.email ||'',
  };
  
  const validate = (values) => {
    const errors = {};
    if (!values.entreprise_equipe) {
      errors.entreprise_equipe = 'Champs requis';
    }
    if (!values.firstName) {
      errors.firstName = 'Champs requis';
    }
    if (!values.lastName) {
      errors.lastName = 'Champs requis';
    }
    if (!values.stade ) {
      errors.stade = 'Champs requis';
    }
    if (!values.email) {
      errors.email = 'Champs requis';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }


    return errors;
  };
 
 
  const onSubmit = (values) => {
    // submit form data here
    console.log(values);
    Services.update(values,idToEdited, 'user').then((res)=>{
      console.log(res)
      setTimeout(()=>{ setIsOpenEdit(false);},1000);
    })
    .catch((err)=>{
      console.log(err);
    })
  };

  const hideModal = () => {
    setIsOpenEdit(false);
  };

  return (

    <Modal show={isOpenEdit} onHide={hideModal} className="modal-up">
      <Modal.Header>
        <Modal.Title>
        
           <h3>Détail animateurs</h3> 
        
      
     </Modal.Title>  
   <button className="btn btn-link" onClick={()=>setIsOpenEdit(false)}>
    <img src={closeIcon} alt="close icon" />  </button> 
      </Modal.Header>
      <Modal.Body>
      {animateur ? ( 
         <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row">
            <div className='col-md-6'>
              <label htmlFor="entreprise_equipe">Entreprise/Equipe</label>
              <Field type="text" name="entreprise_equipe" className="form-control" />
              {errors.entreprise_equipe && touched.entreprise_equipe && <div className='text-danger'>{errors.entreprise_equipe}</div>}
            </div>
            <div className='col-md-6'>
              <label htmlFor="email">Email</label>
              <Field type="email" name="email" className="form-control" readOnly/>
              {errors.email && touched.email && <div className='text-danger'>{errors.email}</div>}
            </div>
            </div>
            <div className="row  mt-30">
            <div className='col-md-6'>
              <label htmlFor="firstName">Nom</label>
              <Field type="text" name="firstName" className="form-control" />
              {errors.firstName && touched.firstName && <div className='text-danger'>{errors.firstName}</div>}
            </div>
            <div className='col-md-6'>
              <label htmlFor="lastName">Prénom</label>
              <Field type="text" name="lastName" className="form-control"/>
              {errors.lastName && touched.lastName && <div className='text-danger'>{errors.lastName}</div>}
            </div>
            <div className='col-md-6  mt-30'>
              <label htmlFor="lastName">Stade</label>
              <Field 
                name="stade" 
                component="select" 
                id="stade" 
                className="form-select"
                value={animateur?.stade?._id}
              >
                <option value="">Select option</option>
                {stades.map((stade)=>
                 <option value={stade?._id}  >{stade?.name}</option>
                )}
                 
              </Field>
              {errors.stade && touched.stade && <div className='text-danger'>{errors.stade}</div>}
            </div>
            </div>
           
            <button type="submit" className="btn-primary-up right mt-5">Sauvegarder</button>
          </Form>
        )}
      </Formik>
       ) : (
        <p>Loading animateur details...</p>
      )}
   </Modal.Body>
    </Modal>
  );
}

export default EditAnimateur;
