import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import '../assets/css/modal.css'
import Services from "../services/global.service";
import closeIcon from '../assets/img/svg/close-outline.svg';
import { useState } from "react";
const initialValues  = {
  date: '',
  title: '',
  startTime: '',
  endTime: '',
  stade:'',
  createdBy:localStorage.getItem('id')
};

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Champs requis'),
  title: Yup.string().required('Champs requis'),
  startTime: Yup.string().required('Champs requis'),
  endTime: Yup.string().required('Champs requis'),
  stade:Yup.string().required('Champs requis')

});

const ModalUp = ({ isOpen, setIsOpen, stades }) => {
  const hideModal = () => {
    setIsOpen(false);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  const [startTime, setStartTime] = useState('');
  const handleSubmit = (values) => {
    console.log("values", values);
    const dateTimeString = values?.date;
    const timeString = values?.startTime;
    const timeEndString = values?.endTime;
    const dateTime = new Date(dateTimeString);
    const dateTimeEnd = new Date(dateTimeString);
    const [hours, minutes] = timeString.split(":");
    const [hoursEnd, minutesEnd] = timeEndString.split(":");
    dateTime.setHours(hours);
    dateTime.setMinutes(minutes);
    dateTimeEnd.setHours(hoursEnd);
    dateTimeEnd.setMinutes(minutesEnd);
    console.log(dateTime);
    values.dateStart=dateTime;
    values.dateEnd=dateTimeEnd;
    Services.create(values, "event/create").then(
      (response) => {
        console.log(response)
        setTimeout(()=>{ setIsOpen(false);},1000);
       
      },
      (error) => {
        console.log(error)
      }
    );
  };

  return (
    <Modal show={isOpen} onHide={hideModal} className="modal-up">
      <Modal.Header>
        <Modal.Title>
        
           <h3>Créer un évènement</h3> 
        
      
     </Modal.Title>  
   <button className="btn btn-link" onClick={()=>setIsOpen(false)}><img src={closeIcon} alt="close icon" />  </button> 
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ errors, touched, values }) => (
            <Form>
              <div className="row">
                <div className="col-6 mb-3">
                  <label htmlFor="date">Date</label>
                  <Field type="date" name="date" id="date"  className="form-control" min={currentDate} />
                  {errors.date && touched.date ? <div className='text-danger'>{errors.date}</div> : null}
                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="title">Titre de l’évènement</label>
                  <Field type="text" name="title" id="title"  className="form-control" placeholder="Titre de l’évènement" />
                  {errors.title && touched.title ? <div className='text-danger'>{errors.title}</div> : null}
                </div>
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  <label htmlFor="startTime">Heure de début</label>
                  <Field type="time" name="startTime" id="startTime"   className="form-control" />
                  {errors.startTime && touched.startTime ? <div className='text-danger'>{errors.startTime}</div> : null}
                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="endTime">Heure de fin</label>
                  <Field type="time" name="endTime" id="endTime" min={values.startTime}  className="form-control"  />
                  {errors.endTime && touched.endTime ? <div className='text-danger'>{errors.endTime}</div> : null}
                </div>
              </div>
              <div className='col-6 mb-3'>
              <label htmlFor="stade">Stade</label>
              <Field 
                name="stade" 
                component="select" 
                id="stade" 
                className="form-select"
              ><option value="">Sélectionner un stade </option>
                {stades.map((stade)=>
                 <option value={stade?._id} >{stade?.name}</option>
                )}
                 
              
              </Field>
              {errors.stade && touched.stade && <div className='text-danger'>{errors.stade}</div>}
            </div>
              <div className="modal-footer">
                <button type="submit"  className="btn-primary-up" >Créer un évènement</button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default ModalUp;
