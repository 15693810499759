import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import '../assets/css/modal.css'
import { useParams } from 'react-router-dom';
import Services from "../services/global.service";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import closeIcon from '../assets/img/svg/close-outline.svg';




const ModalAnimation = ({ isOpen, setIsOpen }) => {
  const  params  = useParams();
const initialValues  = {
  type:'',
  event:params?.id
};
const validationSchema = Yup.object().shape({
  type: Yup.string().required('Champs requis'),
  
});
  const hideModal = () => {
    setIsOpen(false);
  };
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    console.log("values", values);

    // Services.create(values, "animation/create").then(
    //   (response) => {
    //     console.log(response)
    //     console.log(response)
        //redirect to create up interface
        if(values){
           if(values.type==="OLA"){
          navigate(`/dashboard/events/animation/up/${params?.id}`)
        }
         //redirect to create chant interface
         if(values.type==="CHANT"){
          navigate(`/dashboard/events/animation/chant/${params?.id}`)
        }
        }
       
        
        
    //   },
    //   (error) => {
    //     console.log(error)
    //   }
    // );
  };

  const [step, setStep] = useState(1);

  // proceed to the next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // go back to the previous step
  const prevStep = () => {
    setStep(step - 1);
  };
  return (
    <Modal show={isOpen} onHide={hideModal} className="modal-up">
      <Modal.Header>
        <Modal.Title><h3>Programmer une animation</h3></Modal.Title>
   <button isrtl={true} className="btn btn-link" onClick={()=>setIsOpen(false)}><img src={closeIcon} alt="close icon" />  </button> 

      </Modal.Header>
      <Modal.Body>
        {/* <span className="step-counter">(Étape {step}/1)</span> */}
        <br></br>
        <label className="sub-title">Choisir le type d’animation </label>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <div className= {step===1 ? "row" :"row d-none"} id="step1">
                <div className="col-6 mb-3">
                  <label htmlFor="OLA" className={values.type==='OLA' ?"radio-anim checked-radio":"radio-anim"}> 
                    <input
                      type="radio"
                      value="OLA"
                      name="type"
                      id="OLA"
                      className="form-check-input"
                      checked={values.type === "OLA"}
                      onChange={() => setFieldValue("type", "OLA")}
                    />
                    UP
                  </label>
                  {errors.type && touched.type ? <div>{errors.type}</div> : null}
                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="CHANT" className={values.type==='CHANT' ?"radio-anim checked-radio":"radio-anim"}> 
                    <input
                      type="radio"
                      value="CHANT"
                      name="type"
                      id="CHANT"
                      className="form-check-input"
                      checked={values.type === "CHANT"}
                      onChange={() => setFieldValue("type", "CHANT")}
                    />
                    CHANT
                  </label>
                  {errors.type && touched.type ? <div>{errors.type}</div> : null}
                </div>
              </div>
              {/* <div className={step===2 ? "row" :"row d-none"} id="step2">
                {values.type==="up" ?<span>Créer un UP</span> :<span>Créer une CHANT</span>}
                <div className="col-6 mb-3">
                  <label htmlFor="avec" className={values.model==='avec' ?"radio-anim checked-radio":"radio-anim"}> 
                    <input
                      type="radio"
                      value="avec"
                      name="model"
                      id="avec"
                      className="form-check-input"
                      checked={values.model === "avec"}
                      onChange={() => setFieldValue("model", "avec")}
                    />
                    Créer à partir d’un modèle
                  </label>
                  {errors.model && touched.model ? <div>{errors.model}</div> : null}
                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="sans" className={values.model==='sans' ?"radio-anim checked-radio":"radio-anim"}> 
                    <input
                      type="radio"
                      value="sans"
                      name="model"
                      id="sans"
                      className="form-check-input"
                      checked={values.model === "sans"}
                      onChange={() => setFieldValue("model", "sans")}
                    />
                    Continuer sans modèle
                  </label>
                  {errors.model && touched.model ? <div>{errors.model}</div> : null}
                </div>
              </div> */}
              {/* {values.model==="avec" ?<div className="row">
              <div className="col">
              <label htmlFor="model_id">Choisir un modèle  </label>
              <Field 
                name="model_id" 
                component="select" 
                id="model_id" 
                className="form-select"
                onChange={(event) => {
                  const newValue = event.target.value;
                  setFieldValue('model_id', newValue);
              }}>
                 <option value="">Select option</option>
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
              </Field>
              </div>
             </div> :""} */}
             
              <div className="modal-footer row">
                {/* <div className="col">{step===1 ? "" :<button className="btn-secondary-up "  onClick={()=>{prevStep()}} >Précédent</button> } </div>
                <div className="col"> */}
                  {/* <button className="btn-primary-up right" 
                disabled={ (step === 1 && !values.type) ||
                (step === 2 && !values.model)} onClick={()=>{nextStep()}} >Suivant</button> */}
                
                {/* {step===1 
                ? <button className="btn-primary-up right" 
                disabled={!values.type} onClick={()=>{nextStep()}} >Suivant</button>
                : <button className="btn-primary-up right" 
                disabled={ ( !values.model) ||
                  (values.model==="avec" && values.model_id==="")} onClick={()=>{handleSubmit()}} >Créer l’animation</button>
                } */}
                {/* </div> */}
                <div className="col"><button isrtl={true} className="link" onClick={()=>{setIsOpen(false)}}>Annuler</button></div>

                <div className="col"> <button isrtl={true} className="btn-primary-up right" 
                disabled={ ( !values.type)} onClick={()=>{handleSubmit()}} >Créer l’animation</button></div>
               
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAnimation;
